<template>
  <div class="flex-col page">
    <Wapheader />
    <div class="flex-col space-y-66">
      <div class="flex-col">
        <div class="flex-col group_44">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176125150080117.png"
            class="image_36" />
          <div class="flex-col group_44">
            <div style="opacity: 0;" class="flex-row justify-between section_2">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/7aac57f92046566f1b38145db82c5d21.png"
                class="image" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966223060878.png"
                class="image_1" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966407546710.png"
                class="image_2" />
            </div>
            <div class="flex-row justify-center group_2 view">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176125793174878.png"
                class="image_3" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176125901070461.png"
                class="image_4" />
            </div>
            <div class="flex-row justify-center space-x-45 section_3">
              <div class="flex-col items-center text-wrapper"><span class="text" @click="navPath('/newwapyy')">预约试驾</span></div>
              <div class="flex-col items-center text-wrapper_1"><span class="text_1">购买商城</span></div>
            </div>
          </div>
        </div>
        <div class="flex-col section_4">
          <div class="flex-col space-y-414 section_5">
            <div class="group_3">
              <span class="text_2">
                国内首款复古智能
                <br />
              </span>
              <span class="text_3">
                电动摩托车
                <br />
              </span>
              <span class="text_4">
                来自意大利设计大师
                <br />
              </span>
              <span class="text_5">
                经典之作
                <br />
              </span>
            </div>
            <div class="flex-row justify-center button">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176124977275242.png"
                class="image_5" />
              <span class="text_6">观看完整影片</span>
            </div>
          </div>
        </div>
        <div class="flex-col space-y-60 section_6">
          <div class="flex-col group_4">
            <div class="group_5">
              <span class="text_7">
                『参数化肌理』
                <br />
              </span>
              <span class="text_8">
                前面板来自概念汽车设计理念
                <br />
              </span>
              <span class="text_9">型面生动、更具科技感</span>
            </div>
            <div class="flex-col items-start group_6">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176121447348919.png"
                class="image_6" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176123037746537.png"
                class="image_7" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176123073457817.png"
                class="image_8" />
            </div>
          </div>
          <div class="flex-col space-y-4 group_7">
            <div class="flex-col items-start group_8">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176120408719130.png"
                class="image_9" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176120579260174.png"
                class="image_10" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176121359197112.png"
                class="image_11" />
            </div>
           
          </div>
          <div class="group_9">
              <span class="text_10">
                整车汲取了复古摩托车的经典动态线条
                <br />
              </span>
              <span class="text_11">
                大胆运用汽车设计语言
                <br />
              </span>
              <span class="text_12">
                使i-Like整体造型演绎出
                <br />
              </span>
              <span class="text_13">摩登复古、未来科技</span>
              <span class="text_14">
                的全新格调
                <br />
              </span>
              <span class="text_15">属于行业首创自成一派</span>
          </div>
        </div>
      </div>
      <div class="flex-col space-y-31 group_10">
        <div class="group_11">
          <span class="text_16">
            电车的安静优雅，
            <br />
          </span>
          <span class="text_17">
            油车的加速体验，
            <br />
          </span>
          <span class="text_18">乐在骑中。</span>
        </div>
        <div>
          <span class="text_19">
            汲取自意大利艺术家乔治·莫兰迪灰系调色灵感，
            <br />
          </span>
          <span class="text_20">
            以低饱和蓝白为基调，辅以咖棕座椅点缀，
            <br />
          </span>
          <span class="text_21">凝练出的低调高级感。</span>
        </div>
      </div>
    </div>
    <img
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176116770185378.png"
      class="image_12" />
    <div class="flex-col space-y-18 group_13">
      <span class="text_22">行业首款</span>
      <div>
        <span class="text_23">
          3KW正弦波内转子侧挂电机
          <br />
        </span>
        <span class="text_24">内部集成高效变速箱峰值扭矩高达17N.m</span>
      </div>
    </div>
    <img
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176118441677107.png"
      class="image_13" />
    <div class="flex-col space-y-18 group_15">
      <span class="text_25">PTS油门控制技术</span>
      <div>
        <span class="text_26">
          媲美燃油车的油门操控性的PTS油门控制技术
          <br />
        </span>
        <span class="text_27">在享受线性加速的同时，还可获得更好的加速感</span>
      </div>
    </div>
    <img
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176118784413869.png"
      class="image_14 image_35" />
    <div class="flex-col space-y-17 group_17">
      <span class="text_28">经得起，稳得住</span>
      <div>
        <span class="text_29">
          轻量化铝合金锻造平叉设计，降服得住刁钻道
          <br />
        </span>
        <span class="text_30">路的颠簸，一路体验更好的骑乘乐趣</span>
      </div>
    </div>
    <img
      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176119431474738.png"
      class="image_14 image_16" />
    <div class="flex-col space-y-18 group_19">
      <span class="text_31">名副骑实的舒适沙发</span>
      <div>
        <span class="text_32">
          运用KYMCO多年积累的舒适人体工学技术
          <br />
        </span>
        <span class="text_33">专为电摩载人量身打造的宽大舒适座垫</span>
      </div>
    </div>
    <div class="flex-col group_21">
      <div class="flex-col space-y-30 section_7">
        <div class="group_22">
          <span class="text_34">
            反复打磨细节，
            <br />
          </span>
          <span class="text_35">优雅得360°无死角。</span>
        </div>
        <div>
          <span class="text_36">
            整车汲取了复古摩托车的经典动态线条。
            <br />
          </span>
          <span class="text_37">
            大胆运用汽车设计语言，使i-Like整体造型演绎出
            <br />
          </span>
          <span class="text_38">摩登复古、未来科技的全新格调。</span>
        </div>
      </div>
      <div data-aos="fade-up" class="swiper swiper1">
        <div class="flex-col group_26">
          <div class="flex-row group_27" :class="translates">
            <div class="text_41" v-for="(item,index) in Nav" :key="index" @click="choose(index)">
              {{item}}
            </div>
          </div>
          <div class="group26_line">

          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swiper-banner1">
              <div class="flex-col items-center space-y-29 section_8">
                <span class="text_39">安全感都是车给的</span>
                <span class="text_40">外表的从容源于内在十足的安全感</span>
              </div>
            </div>

          </div>
          <div class="swiper-slide">
            <div class="swiper-banner2">
              <div class="flex-col items-center space-y-29 section_8">
                <span class="text_39">安全感都是车给的</span>
                <span class="text_40">外表的从容源于内在十足的安全感</span>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-banner3">
              <div class="flex-col items-center space-y-29 section_8">
                <span class="text_39">安全感都是车给的</span>
                <span class="text_40"> 源自内在十足的安全感</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex-col group_24" >
        <div class="flex-col items-center space-y-29 section_8">
          <span class="text_39">安全感都是车给的</span>
          <span class="text_40">外表的从容源于内在十足的安全感</span>
        </div>
        <div class="flex-col group_44 group_25">
          <div class="flex-col group_26">
            <div class="flex-row justify-center group_27">
              <span class="text_41">全天候把握</span>
              <span class="text_42">全面优异</span>
            </div>
            <div class="flex-row justify-center group_44">
              <div class="section_9"></div>
              <div class="section_10"></div>
            </div>
          </div>
          <div class="aqg-banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
              <van-swipe-item>
                <img src="../../assets/images/ilike/banner1.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                  <div class="banner2-content"></div>
              </van-swipe-item>
              <van-swipe-item>
                <img src="../../assets/images/ilike/banner3.png" alt="" style="margin-top:70px;width:95%">
              </van-swipe-item>
            </van-swipe>

          </div>
          
        </div>
      </div> -->
      <constart car="ilikes"></constart>
    </div>

    <Wapfooter />
  </div>
</template>

<script>
import constart from "../../components/Newconstart/index.vue"
import { Swipe, SwipeItem } from 'vant';
export default {
  components: {
    constart,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data() {
    return {
      Nav: ["全天候把握", "全面优异", "全能掌控"],
      translates: ""
    };
  },

  mounted() {
    let that=this
    that.mySwiper = new Swiper('.swiper', {
      effect: 'fade',
      loop: true,
      autoplay: false,//可选选项，自动滑动
      on: {
        slideChangeTransitionStart: function () {
          if (this.realIndex == 0 ) {
            that.translates = ""
            return
          }
          if (this.realIndex == 1) {
            that.translates = "translateX2"
            return
          }
          if (this.realIndex == 2) {
            that.translates = "translateX3"
            return
          }
        },
      },

    })

  },
  methods: {
    choose(index) {
      if (index == 0) {
        this.translates = ""
        this.mySwiper.slideTo(1)
        return
      }
      if (index == 1) {
        this.translates = "translateX2"
        this.mySwiper.slideTo(2)
        return
      }
      if (index == 2) {
        this.translates = "translateX3"
        this.mySwiper.slideTo(3)
        return
      }
    }
  },
};
</script>

<style>
.van-swipe__track {
  display: flex;
}
</style>
<style scoped lang="css">
.translateX2 {
  transform: translateX(-8.5%) !important;
  transition: all 0.5s;
}

.translateX3 {
  transform: translateX(-42%) !important;
  transition: all 0.5s;
}

.swiper1 {
  position: relative;
}

.swiper-banner1 {
  width: 100%;
  height: 1080px;
  background-image: url(../../assets/images/ilike/banner1.png);
  background-size: 100% 100%;
}

.swiper-banner2 {
  width: 100%;
  height: 1080px;
  background-image: url(../../assets/images/ilike/banner2.png);
  background-size: 100% 100%;
}

.swiper-banner3 {
  width: 100%;
  height: 1080px;
  background-image: url(../../assets/images/ilike/banner3.png);
  background-size: 100% 100%;
}

.banner2-content {
  background-image: url(../../assets/images/ilike/banner2.jpg);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.aqg-banner {
  width: 100%;
  height: 690px;

}

.aqg-banner img {
  width: 100%;
}

.hdlist {
  position: relative;
  z-index: 2;
  display: flex;
  width: 600px;
  margin: 36px auto 16px auto;
  list-style: none;
  text-align: center;
  border-bottom: 2px solid #B5B5B5;
  box-sizing: border-box;
  left: 33%;
  transform: translateX(var(--safety-trigger-offset));
  transition: transform .5s;
}

.hdlist>div {
  width: 33%;
  color: #040000;
  font-size: 3rem;
  font-family: 'Albb';
  line-height: 2.9rem;
}

:root {
  --safety-trigger-offset: 20%;
}

.hdlist .hdlist-item1 {
  padding-bottom: 10px;
  border-bottom: 2px solid red;
}

.hdlist .hdlist-item1:hover {
  --safety-trigger-offset: 20%;

}

.hdlist .hdlist-item2:hover {
  --safety-trigger-offset: 50%
}

.page {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.space-y-66>*:not(:first-child) {
  margin-top: 66px;
}

.group_44 {
  position: relative;
}

.image_36 {
  width: 750px;
  height: 1334.00025px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.section_2 {
  padding: 30px 30px 29px;
  background-color: #00000126;
}

.image {
  margin-bottom: 3px;
  width: 40px;
  height: 36px;
}

.image_1 {
  width: 260px;
  height: 41px;
}

.image_2 {
  width: 40px;
  height: 40px;
}

.group_2 {
  padding: 0 142px;
}

.view {
  margin-top: 149px;
}

.image_3 {
  width: 247px;
  height: 49px;
}

.image_4 {
  width: 229px;
  height: 50px;
}

.space-x-45>*:not(:first-child) {
  margin-left: 45px;
}

.section_3 {
  margin-top: 875px;
  padding: 39px 140px 39px;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 18px 0 16px;
  border-radius: 30px;
  width: 212px;
  height: 61px;
  border: solid 2px #040000;
}

.text {
  color: #040000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.text-wrapper_1 {
  padding: 20px 0 18px;
  background-color: #007bff;
  border-radius: 30px;
  width: 212px;
  height: 61px;
}

.text_1 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.section_4 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176124176066371.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.space-y-414>*:not(:first-child) {
  margin-top: 414px;
}

.section_5 {
  padding: 415px 140px 130px 140px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176124929716627.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_3 {
  text-align: center;
  font-family: 'Albb';
}

.text_2 {
  color: #ffffff;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 2.4px;
}

.text_3 {
  color: #ffffff;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 2.4px;
}

.text_4 {
  margin-top: 45px;
  color: #ffffff;
  font-size: 48px;
  /* line-height: 90px; */
  letter-spacing: 2.4px;
}

.text_5 {
  color: #ffffff;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 2.4px;
}

.button {
  padding: 20px 0;
  align-self: center;
  background-color: #ffffff;
  border-radius: 35px;
  width: 300px;
}

.image_5 {
  width: 30px;
  height: 30px;
}

.text_6 {
  margin: 3px 0 4px 21px;
  color: #040000;
  font-size: 24px;
  font-family: 'Albbm';
  line-height: 23px;
  letter-spacing: 1.2px;
}

.space-y-60>*:not(:first-child) {
  margin-top: 60px;
}

.section_6 {
  padding-bottom: 115px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176120083609224.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 2215px;
}

.group_4 {
  margin-top: -31px;
  height: 949px;
  position: relative;
}

.group_5 {
  margin-top: 820px;
  align-self: center;
  line-height: 36px;
  text-align: center;
}

.text_7 {
  color: #040000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 48px;
}

.text_8 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_9 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.group_6 {
  margin-top: -949px;
  padding-top: 630px;
  position: relative;
}

.image_6 {
  width: 372px;
  height: 195px;
}

.image_7 {
  width: 496px;
  height: 196px;
  position: absolute;
  right: 0;
  bottom: 45px;
}

.image_8 {
  width: 667px;
  height: 745px;
  position: absolute;
  left: 0;
  top: 0;
}

.space-y-4>*:not(:first-child) {
  margin-top: 4px;
}

.group_7 {
  align-self: flex-end;
  width: 686px;
}

.group_8 {
  padding-top: 604px;
  position: relative;
}

.image_9 {
  width: 415px;
  height: 274px;
}

.image_10 {
  width: 395px;
  height: 274px;
  position: absolute;
  right: 0;
  bottom: 134px;
}

.image_11 {
  width: 559px;
  height: 770px;
  position: absolute;
  right: 0;
  top: 0;
}

.group_9 {
  align-self: center;
  text-align: center;
}

.text_10 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_11 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_12 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_13 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albb';
  line-height: 48px;
  font-weight: 700;
}

.text_14 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_15 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.space-y-31>*:not(:first-child) {
  margin-top: 31px;
}

.group_10 {
  align-self: center;
  width: 600px;
}

.group_11 {
  align-self: flex-start;
  font-family: 'Albb';
}

.text_16 {
  color: #040000;
  font-size: 48px;

  line-height: 60px;
}

.text_17 {
  color: #040000;
  font-size: 48px;
  line-height: 60px;
}

.text_18 {
  color: #040000;
  font-size: 48px;
  line-height: 60px;
}

.text_19 {
  color: #676767;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_20 {
  color: #676767;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_21 {
  color: #676767;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 48px;
}

.image_12 {
  margin-top: 137px;
  align-self: center;
  width: 591px;
  height: 738px;
}

.space-y-18>*:not(:first-child) {
  margin-top: 18px;
}

.group_13 {
  margin-left: 78px;
  margin-top: 72px;
  align-self: flex-start;
  width: 516px;
}

.text_22 {
  align-self: flex-start;
  color: #040000;
  font-size: 38px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_23 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 43px;
}

.text_24 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 43px;
}

.image_13 {
  margin-top: 114.5px;
  align-self: center;
  width: 590px;
  height: 739px;
}

.group_15 {
  margin-top: 61px;
  align-self: center;
  width: 590px;
}

.text_25 {
  align-self: flex-start;
  color: #040000;
  font-size: 38px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_26 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 41px;
}

.text_27 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 41px;
}

.image_14 {
  align-self: center;
  width: 590px;
  height: 738px;
}

.image_35 {
  margin-top: 117.5px;
}

.space-y-17>*:not(:first-child) {
  margin-top: 17px;
}

.group_17 {
  margin-top: 65px;
  align-self: center;
  width: 590px;
}

.text_28 {
  align-self: flex-start;
  color: #040000;
  font-size: 38px;
  font-family: 'Albb';
  line-height: 38px;
}

.text_29 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 41px;
}

.text_30 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 41px;
}

.image_16 {
  margin-top: 116.5px;
}

.group_19 {
  margin-top: 77px;
  align-self: center;
  width: 590px;
}

.text_31 {
  align-self: flex-start;
  color: #040000;
  font-size: 38px;
  font-family: 'Albb';
  line-height: 37px;
}

.text_32 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 41px;
}

.text_33 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 41px;
}

.group_21 {
  margin-top: 270.5px;
}

.space-y-30>*:not(:first-child) {
  margin-top: 30px;
}

.section_7 {
  padding: 977px 62px 111px 79px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176113085198713.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_22 {
  align-self: flex-start;
}

.text_34 {
  color: #040000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 48px;
}

.text_35 {
  color: #040000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 48px;
}

.text_36 {
  color: #676767;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 40px;
}

.text_37 {
  color: #676767;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 40px;
}

.text_38 {
  color: #676767;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 40px;
}

.group_24 {}

.space-y-29>*:not(:first-child) {
  margin-top: 29px;
}

.section_8 {
  padding: 88px 0 891px;
  /* background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633176113072836046.png'); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
}

.text_39 {
  color: #040000;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
}

.text_40 {
  color: #040000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 27px;
}

.group_25 {
  margin-top: -863px;
}

.group_26 {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  z-index: 99;

}

.group26_line {
  width: 300px;
  height: 2px;
  background: #1C95D4;
  position: relative;
  top: -2px;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
}

.group_27 {
  /* padding: 43px 42px 21px; */
  position: relative;
  margin-top: 15px;
  border-bottom: 2px solid #CBCBCB;
  transition: all 0.5s;
  transform: translateX(25%);
  width: 900px;
}

.text_41 {
  color: #040000;
  font-size: 30px;
  font-family: 'Albb';
  line-height: 70px;
  text-align: center;
  width: 300px;
}

.text_42 {
  color: #040000;
  font-size: 30px;
  font-family: 'Albb';
  line-height: 70px;
  text-align: center;
  width: 300px;
}

.section_9 {
  background-color: #1c95d4;
  width: 270px;
  height: 4px;
}

.section_10 {
  background-color: #b5b5b5;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 240px;
  height: 4px;
}

.image_17 {
  align-self: center;
  width: 750px;
  height: 396px;
}

.space-x-63>*:not(:first-child) {
  margin-left: 63px;
}

.group_29 {
  margin-top: 52px;
  align-self: center;
  width: 553px;
}

.image_18 {
  flex-shrink: 0;
  width: 197px;
  height: 212px;
}

.group_30 {
  flex: 1 1 auto;
  align-self: center;
  text-align: center;
  height: 96px;
}

.text_43 {
  color: #040000;
  font-size: 40px;
  font-family: 'Albb';
  line-height: 48px;
}

.text_44 {
  color: #040000;
  font-size: 40px;
  font-family: 'Albb';
  line-height: 48px;
}

.space-y-54>*:not(:first-child) {
  margin-top: 54px;
}

.group_31 {
  margin-top: 149px;
  padding: 0 41px;
}

.text_45 {
  align-self: center;
  color: #000;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 41px;
}

.group_32 {
  margin-right: 35px;
}

.group_33 {
  padding-top: 398px;
  position: relative;
}

.image_19 {
  width: 633px;
  height: 166px;
}

.image_20 {
  width: 526px;
  height: 509px;
  position: absolute;
  right: 34px;
  top: 0;
}

.view_2 {
  margin-top: 19px;
}

.text_46 {
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 30px;
}

.text_47 {
  margin-bottom: 3px;
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 30px;
}

.text_48 {
  margin-right: 254px;
  margin-top: 35px;
  align-self: flex-end;
  color: #67cfdc;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
}

.space-x-44>*:not(:first-child) {
  margin-left: 44px;
}

.group_34 {
  margin-top: 40px;
}

.image_21 {
  width: 51px;
  height: 51px;
}

.group_35 {
  margin-top: 44px;
  align-self: center;
  line-height: 36px;
  text-align: center;
}

.text_49 {
  color: #000;
  font-size: 24px;
  font-family: OPPOSans;
  line-height: 36px;
}

.text_50 {
  color: #000;
  font-size: 24px;
  font-family: OPPOSans;
  line-height: 36px;
}

.button_1 {
  margin-top: 47px;
  padding: 26px 0;
  align-self: center;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #0b0306;
}

.text_51 {
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 32px;
}

.group_36 {
  margin-top: 112px;
}

.image_24 {
  width: 750px;
  height: 2862.99975px;
}
</style>